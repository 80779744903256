<template>
  <!-- Add new province modal -->
  <b-modal
    id="modal-new-province"
    :visible="isEditProvinceModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.save')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('province.form.header')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('province.form.name')"
        label-for="province"
        :invalid-feedback="$t('province.form.nameInvalid')"
      >
        <b-form-input
          id="province"
          v-model="provinceName"
          type="text"
          :state="nameState"
          :placeholder="$t('province.form.namePlaceholder')"
          required
        />
      </b-form-group>
      <b-form-group label="Status" label-for="status">
        <div>
          <b-form-checkbox
            :checked="provinceState"
            class="custom-control-success"
            name="check-button"
            switch
            @change="updateState"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </form>
    <template v-slot:modal-ok>
      <div class="d-flex align-center">
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t('common.save') }}
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditProvinceModalShow', 'data'],
    event: ['update:is-edit-province-modal-show', 'update:data'],
  },
  props: {
    isEditProvinceModalShow: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const provinceName = ref('')
    const provinceState = ref(false)
    const nameState = ref(null)
    const form = ref(null)
    const isLoading = ref(false)

    const toast = useToast()

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    watch(
      () => props.isEditProvinceModalShow,
      val => {
        if (val) {
          provinceName.value = props.data.province_name
          provinceState.value = props.data.is_active
        }
      },
    )

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      isLoading.value = true
      // EDIT ETHNIC NAME //
      store
        .dispatch('master-province/editProvince', {
          id: props.data._id,
          province_name: provinceName.value,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-province-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success editing a province',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error editing a province',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // UPDATE STATUS ACTIVE INACTIVE
    const updateState = () => {
      // eslint-disable-next-line no-underscore-dangle
      store.dispatch('master-province/updateProvinceState', {
        id: props.data._id,
        isActive: !provinceState.value,
      })
      emit('refetch-data')
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (props.data.province !== provinceName.value) {
        handleSubmit()
      } else {
        emit('update:is-edit-province-modal-show', false)
      }
    }

    const resetModal = () => {
      provinceName.value = ''
      nameState.value = null
      emit('update:is-edit-province-modal-show', false)
      emit('update:data', null)
    }

    return {
      provinceName,
      provinceState,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      updateState,
      isLoading,
    }
  },
}
</script>
