<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <province-list-add-new
          v-if="$can('create', 'Province')"
          :is-add-new-province-modal-show.sync="isAddNewProvinceModalShow"
          @refetch-data="refetchData"
        />

        <province-list-edit
          v-if="$can('update', 'Province')"
          :is-edit-province-modal-show.sync="isEditProvinceModalShow"
          :data.sync="selectedProvince"
          @refetch-data="refetchData"
        />

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('common.entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="$can('create', 'Province')"
            variant="primary"
            @click="isAddNewProvinceModalShow = true"
          >
            {{ $t('province.addBtn') }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              debounce="500"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search') + '...'"
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="status => status.value"
              class="province-filter-select"
              :placeholder="$t('common.filter.status')"
              label="text"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refProvinceListTable"
      :items="fetchProvinces"
      responsive
      :fields="translatedTableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('message.empty')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      no-sort-reset
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner variant="primary" label="Loading..." />
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(is_active)="data">
        <template v-if="data.value">
          <b-badge pill variant="light-success">
            Active
          </b-badge>
        </template>
        <template v-else>
          <b-badge pill variant="light-secondary">
            Inactive
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div v-if="$can('update', 'Province')" class="text-nowrap">
          <feather-icon
            :id="`province-row-${data.item._id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openEditProvinceModal(data.item)"
          />
          <b-tooltip
            :title="$t('province.tooltip')"
            class="cursor-pointer"
            :target="`province-row-${data.item._id}-send-icon`"
            triggers="hover"
            placement="left"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{
            $tc('common.showing', dataMeta.of, {
              first: dataMeta.from,
              last: dataMeta.to,
              total: dataMeta.of,
            })
          }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProvince"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useProvincesList from './useProvinceList'
import ProvinceListAddNew from './ProvinceListAddNew.vue'
import ProvinceListEdit from './ProvinceListEdit.vue'

import provinceStoreModule from '../provinceStoreModule'
import 'animate.css'

export default {
  components: {
    ProvinceListAddNew,
    ProvinceListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BBadge,
    BPagination,
    // BDropdown,
    // BDropdownItem,
    BSpinner,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`province.tableHeader.${column.key}`),
      }))
    },
  },

  setup() {
    const PROVINCE_MASTER_STORE_MODULE_NAME = 'master-province'

    // Register module
    if (!store.hasModule(PROVINCE_MASTER_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVINCE_MASTER_STORE_MODULE_NAME,
        provinceStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROVINCE_MASTER_STORE_MODULE_NAME))
        store.unregisterModule(PROVINCE_MASTER_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const isAddNewProvinceModalShow = ref(false)
    const isEditProvinceModalShow = ref(false)
    const selectedProvince = ref(null)

    const openEditProvinceModal = data => {
      selectedProvince.value = data
      isEditProvinceModalShow.value = true
    }

    const {
      fetchProvinces,
      refetchData,
      deleteProvince,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refProvinceListTable,
      currentPage,
      totalProvince,
      dataMeta,
    } = useProvincesList()

    return {
      fetchProvinces,
      refetchData,
      deleteProvince,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      isSortDirDesc,
      tableColumns,
      refProvinceListTable,
      totalProvince,
      currentPage,
      dataMeta,

      openEditProvinceModal,
      isAddNewProvinceModalShow,
      isEditProvinceModalShow,
      selectedProvince,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.province-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
