import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProvinces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/province', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProvince(ctx, provinceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/province/create', { province: provinceData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProvince(ctx, { id, province_name }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/province/update/${id}`, { province_name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProvinceState(ctx, { id, isActive }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/province/setstatus', { id, status: isActive })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProvince(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/province/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
